import React, { Component } from "react";
import styled from "styled-components";
import App from "./components/App/index";
import { ConfigSchema } from "./config";
import styles from "./index.module.scss";
export default class Widget extends Component<ConfigSchema> {
  render() {
    const { func, style } = this.props;
    const Title = styled.div`
      background-color: ${style.backColor};
    `;
    return (
      <Title className={styles.app}>
        <App {...func} {...style} />
      </Title>
    );
  }
}
