import React, { Component } from "react";
import styled from "styled-components";
import App from "./components/App/index";
import { ConfigSchema } from "./config";
import styles from "./index.module.scss";
export default class Widget extends Component<ConfigSchema> {
  render() {
    const { func, style, data } = this.props;
    const Title = styled.div``;
    return (
      <Title className={styles.app}>
        <App {...func} {...style} {...data} />
      </Title>
    );
  }
}
