const data: any = {
  func: {
    mainTitle: "技术支撑",
    subTitle: "强大的底层技术支撑，提升开发效率及质量",
    cardList: [
      {
        iconImageUrl:
          "//img10.360buyimg.com/imagetools/s140x140_jfs/t1/111012/19/17779/880/5f602918Ec9af543d/cf576fccd5a12499.png!q80",
        mainTitle: "API网关",
        desc:
          "网关提供精准流控、授权鉴权、监控告警、防攻击、防重放、防篡改、APP防刷等安全防控功能，为开发者提供安全稳定的开发环境。",
      },
      {
        iconImageUrl:
          "//img10.360buyimg.com/imagetools/s140x140_jfs/t1/145708/10/8432/430/5f602925E6e19a614/d74611c2249a77ba.png!q80",
        mainTitle: "云鼎安全服务",
        desc:
          "一站式零售应用云计算平台，为开发者提供弹性的云计算资源、可靠的安全服务、高效的数据推送服务、标准化的网关服务。",
      },
      {
        iconImageUrl:
          "//img12.360buyimg.com/imagetools/s140x140_jfs/t1/130891/31/9920/4144/5f602930E10849af7/0044e168ae95345b.png!q80",
        mainTitle: "JDOS技术赋能",
        desc:
          "提供物理机/虚拟机/容器的统一管理、灵活的网络互连、可靠的块存储，开发者可迅速得到计算、存储、网络、安全等资源和能力。",
      },
      {
        iconImageUrl:
          "//img12.360buyimg.com/imagetools/s140x140_jfs/t1/119017/8/17912/2369/5f60293bE26894a46/6497dd6882880b9c.png!q80",
        mainTitle: "授权登录",
        desc:
          "基于OAuth2.0协议让京东用户使用京东身份登录第三方应用的服务, 用户授权后第三方可以获取用户的调用凭证，实现授权登录。",
      },
    ],
  },
  style: {
    backImageUrl:
      "//img10.360buyimg.com/imagetools/s1920x948_jfs/t1/148375/8/8548/148400/5f6028c9E2f39b60e/5378ea009fc09223.png!q80",
    padding: "90px 120px 40px",
    backColor: "#FFFFFF",
  },
  data: {},
  _clstag_: "jdzeus_homepage_zeus_homepage_tec_support",
};
export default data;
