import React from "react";
import style from "./index.module.scss";
import * as Type from "../../config";
export interface IAppProps {
  mainTitle: string;
  backImageUrl?: string;
  backColor: string;
  flexUrl: string;
  getData: [];
}
const App: React.SFC<IAppProps> = props => {
  const { mainTitle, backImageUrl, backColor, flexUrl, getData } = props;

  return (
    <div
      className={style.zeusFlexWrap}
      style={{ backgroundImage: `url(${backImageUrl})`, backgroundColor: `${backColor}` }}
    >
      <div className={style.zeusFlexHd}>
        <h3 className={style.zeusFlexTitle}>{mainTitle}</h3>
      </div>
      <div className={style.zeusFlexBd}>
        <ul className={style.zeusFlexList}>
          {getData.map((item: { flowTitle: string; flowIcon: string; flowUrl: string; flowColor: string }, index) => {
            return (
              <li className={style.zeusFlexItem} key={index}>
                <div className={style.zeusFlexImg}>
                  <img src={item.flowIcon} className={style.zeusFlexImgO} />
                </div>
                <div className={style.zeusFlexContent}>
                  <h4 className={style.zeusFlexContentTitle}>{item.flowTitle}</h4>
                  <a
                    href={item.flowUrl}
                    className={style.zeusFlexContentView}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    操作文档
                  </a>
                </div>
                <div className={style.zeusFlexDot} style={{ borderColor: item.flowColor }}></div>
              </li>
            );
          })}
        </ul>
        <a href={flexUrl} className={style.zeusFlexButton} target="_blank" rel="noopener noreferrer nofollow">
          立即入驻
        </a>
      </div>
    </div>
  );
};

export default App;
