import * as React from "react";
import * as ReactDOM from "react-dom";

import { EnhanceWidget, fetchDataSource } from "@jdwork/jdwork-sdk";

import ZeusHomepageHeader from "@/widgets/zeus_homepage_header";
import ZeusHomepageHeaderData from "./data/zeus_homepage_header/data";

import ZeusHomepageFooter from "@/widgets/zeus_homepage_footer";
import ZeusHomepageFooterData from "./data/zeus_homepage_footer/data";

import ZeusHomepageFloatRightbar from "@/widgets/zeus_homepage_float_rightbar";
import ZeusHomepageFloatRightbarData from "./data/zeus_homepage_float_rightbar/data";

class Home extends React.Component {
  dataSourceMap: Record<string, any>;
  constructor(props) {
    super(props);
    this.dataSourceMap = new Map();
  }
  componentDidMount() {
    this.dataSourceMap.size > 0 && fetchDataSource(this.dataSourceMap);
  }
  render() {
    const { dataSourceMap } = this;
    return (
      <>
        <div className="jdw-container">
          <div className="jdw-row">
            <div className="jdw-column">
              <div className="jdw-widget" data-name="zeus_homepage_header" data-version="16.1.4">
                <EnhanceWidget
                  config={{ ...ZeusHomepageHeaderData }}
                  dataSourceMap={dataSourceMap}
                  widget={ZeusHomepageHeader}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="jdw-container" id="1614760882214">
          <div className="jdw-row">
            <div className="jdw-column">{this.props.children}</div>
          </div>
        </div>

        <div className="jdw-container">
          <div className="jdw-row">
            <div className="jdw-column">
              <div className="jdw-widget" data-name="zeus_homepage_footer" data-version="9.0.2">
                <ZeusHomepageFooter {...ZeusHomepageFooterData} />
              </div>
            </div>
          </div>
        </div>

        <div className="jdw-container">
          <div className="jdw-row">
            <div className="jdw-column">
              <div className="jdw-widget" data-name="zeus_homepage_float_rightbar" data-version="1.2.2">
                <ZeusHomepageFloatRightbar {...ZeusHomepageFloatRightbarData} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
