import React from "react";
import style from "./index.module.scss";
import styled from "styled-components";
import * as Type from "../../config";
import { Backtop } from "@jdcfe/legao";
export interface IAppProps {
  logo: string;
  copyright: string;
  linkRight: [];
  backColor: string;
}
const App: React.SFC<IAppProps> = props => {
  const { logo, copyright, linkRight, backColor } = props;
  const StyleFooter = styled.div`
    background: ${backColor};
  `;
  return (
    <StyleFooter>
      <div className={style.footer}>
        <div className={style.cont}>
          <div className={style.left}>
            <img src={logo} alt="" width={320} />
            <p>{copyright}</p>
          </div>
          <div className={style.right}>
            {linkRight &&
              linkRight.map((item: { title: string; link: [{ text: string; link: string }] }, index) => (
                <div className={style.list} key={index}>
                  <div className={style.title}>{item.title}</div>
                  <ul>
                    {item.link.map((value, key) => (
                      <li key={key}>
                        <a href={value.link} target="_blank">
                          {value.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div id="gWrapper">
        <Backtop
          onClick={e => console.log(e)}
          onToggle={isShow => {
            document.getElementById("gWrapper").style.display = isShow ? "block" : "none";
          }}
        >
          <div className={style.slidebar}></div>
        </Backtop>
      </div>
    </StyleFooter>
  );
};

export default App;
