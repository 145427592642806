import React from "react";
import style from "./index.module.scss";
import styled from "styled-components";
import * as Type from "../../config";
export interface IAppProps {
  mainTitle: string;
  subTitle: string;
  backImageUrl: string;
  getData: [];
}
const App: React.SFC<IAppProps> = props => {
  const { mainTitle, subTitle, backImageUrl, getData } = props;
  const ZeusMapBd = styled.div`
    backgroundimage: url(${backImageUrl});
  `;
  const TagItem = ({ children, x, y, url }) => (
    <span
      className={style.zeusMapTag}
      style={{
        top: y,
        left: x,
      }}
      onClick={()=>{
        if(!url) return;
        window.open(url)
      }}
    >
      {children}
    </span>
  );
  return (
    <div className={style.zeusMapWrap}>
      <div className={style.zeusMapHd}>
        <h3 className={style.zeusMapTitle}>{mainTitle}</h3>
        <h4 className={style.zeusMapSub}>{subTitle}</h4>
      </div>
      <ZeusMapBd className={style.zeusMapBd} style={{ backgroundImage: `url(${backImageUrl})` }}>
        <div className={style.zeusMapBox}>
          {getData.map(
            (
              item: { mapList: []; imgSrc: string; mapTitle: string; mapPositionX: string; mapPositionY: string },
              i,
            ) => {
              const ZeusMapList = styled.div`
                top: ${item.mapPositionY}px;
                left: ${item.mapPositionX}px;
              `;

              return (
                <ZeusMapList className={style.zeusMapList} key={i}>
                  <div className={style.zeusMapImg}>
                    <img src={item.imgSrc} className={style.zeusMapImgO} />
                  </div>
                  <h5 className={style.zeusMapItitle}>{item.mapTitle}</h5>
                  {item.mapList.map(
                    (childItem: { tagTitle: string; tagPositionX: number; tagPositionY: number }, index) => {
                      return childItem.tagTitle ? (
                        <TagItem key={index} x={childItem.tagPositionX} y={childItem.tagPositionY} url={childItem.tagUrl}>
                          {childItem.tagTitle}
                        </TagItem>
                      ) : null;
                    },
                  )}
                </ZeusMapList>
              );
            },
          )}
        </div>
      </ZeusMapBd>
    </div>
  );
};

export default App;
