const data: any = {
  func: {
    title: "公告：",
    btn: [{ text: "查看全部", link: "/platformlist" }],
    icon: {
      new: { text: "new", bg: "linear-gradient(132deg, rgba(173,92,255,1) 0%,rgba(175,46,255,1) 100%)" },
      arrow:
        "//img13.360buyimg.com/imagetools/s12x44_jfs/t1/138463/40/9671/389/5f7435d4E1dedc9dd/d75c38c7a57901b4.png!q80",
    },
    notification_list_article_link: "http://jos.jd.com/platformdetail?listId=0&itemId=",
  },
  style: { backColor: "linear-gradient(90deg, rgba(19,90,201,1) 0%,rgba(105,46,228,1) 100%)" },
  data: {
    notification_list: {
      type: "remote",
      config: {
        id: 6,
        url: "//joshome.jd.com/doc/getNewJosChannelInfo",
        method: "GET",
        headers: null,
        options: null,
        dataName: "notification_list",
        reqParams: {},
        dynamicParams: {},
        filter: "return data;",
        dataRoute: "",
        pathname: "/doc/getNewJosChannelInfo",
      },
      data: {
        code: 100000,
        success: false,
        message: "success",
        data: {
          totalArticlePages: 1,
          newJosChannel: "平台公告",
          josCmsArticle: [
            {
              id: 89,
              articleTitle: "系统需要维护",
              articleContent: "...",
              articleChannelId: null,
              articleShow: 1,
              articlePublisher: null,
              created: "2020-09-18T22:37:56.000+00:00",
              modified: "2020-09-18T22:38:07.000+00:00",
              articleTemplete: null,
              articleOrder: 0,
              articleLink: null,
              articleType: null,
            },
            {
              id: 88,
              articleTitle: "神灯上线",
              articleContent: "...",
              articleChannelId: null,
              articleShow: 1,
              articlePublisher: null,
              created: "2020-09-18T22:37:33.000+00:00",
              modified: "2020-09-18T22:37:59.000+00:00",
              articleTemplete: null,
              articleOrder: 0,
              articleLink: null,
              articleType: null,
            },
            {
              id: 87,
              articleTitle: "宙斯上线",
              articleContent: "...",
              articleChannelId: null,
              articleShow: 1,
              articlePublisher: null,
              created: "2020-09-18T22:37:11.000+00:00",
              modified: "2020-09-18T22:38:00.000+00:00",
              articleTemplete: null,
              articleOrder: 0,
              articleLink: null,
              articleType: null,
            },
          ],
          totalArticles: 3,
          josCmsChannels: [
            {
              id: 24,
              channelName: "产品发布",
              channelType: null,
              channelShow: 1,
              channelParentId: 0,
              channelLink: null,
              channelContent: null,
              channelOrder: 0,
              channelPublisher: null,
              created: "2020-09-18T22:36:37.000+00:00",
              modified: "2020-09-18T22:36:37.000+00:00",
              channelTemplate: null,
              channelHomeOrder: null,
              startCreated: null,
              endCreated: null,
              startModified: null,
              endModified: null,
              childNodes: [],
              miniChannelName: "产品发布",
              childLinks: [],
              childFaqs: [],
            },
            {
              id: 25,
              channelName: "维护公告",
              channelType: null,
              channelShow: 1,
              channelParentId: 0,
              channelLink: null,
              channelContent: null,
              channelOrder: 1,
              channelPublisher: null,
              created: "2020-09-18T22:36:43.000+00:00",
              modified: "2020-09-18T22:36:43.000+00:00",
              channelTemplate: null,
              channelHomeOrder: null,
              startCreated: null,
              endCreated: null,
              startModified: null,
              endModified: null,
              childNodes: [],
              miniChannelName: "维护公告",
              childLinks: [],
              childFaqs: [],
            },
          ],
        },
        totalCount: null,
        extData: "empty",
      },
      localFilter: "return data;",
    },
  },
  _clstag_: "jdzeus_homepage_zeus_homepage_notification",
};
export default data;
