import React from "react";
import style from "./index.module.scss";
import styled from "styled-components";
import { BannerArr } from "../../config";
export interface IAppProps {
  rightDis: string;
  bannerWidth: string;
  bannerHeight: string;
  bannerArr: BannerArr[];
}
const App: React.SFC<IAppProps> = props => {
  const { bannerArr, rightDis, bannerWidth, bannerHeight } = props;
  const StyledFloor = styled.div`style`;
  return (
    <StyledFloor>
      <div className={style.floatRightbarWrap}>
        {bannerArr &&
          bannerArr.map((item, index) => {
            const ItemStyle = styled.div`
              position: fixed;
              right: ${rightDis};
              bottom: ${item.bottomDis};
              background: ${item.backColor};
              width: ${bannerWidth};
              height: ${bannerHeight};
              border-radius: 4px;
              box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
              &:hover {
                background: ${item.hoverColor};
              }
              img {
                width: 18px;
                height: 18px;
                margin-bottom: 4px;
              }
              .text {
                height: 14px;
                line-height: 14px;
                font-family: PingFangSC-Medium;
                font-size: 12px;
                color: #FFFFFF;
                transform: scale(0.9);
              }
            `;
            return (
              <ItemStyle key={item.imgUrl + index} className={index == "0" ? style.customerService : style.survey}>
                <a href={item.linkUrl} style={{}} target="_blank" rel="noopener noreferrer">
                  <img src={item.imgUrl} alt={item.text} title={item.imgTitle}></img>
                  <span className="text">{item.text}</span>
                </a>
              </ItemStyle>
            );
          })}
      </div>
    </StyledFloor>
  );
};

export default App;
