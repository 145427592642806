import React from "react";
import style from "./index.module.scss";
import * as Type from "../../config";
export interface IAppProps {
  mainTitle: string;
  subTitle: string;
  backImageUrl: string;
  cardList: Type.CardList[];
  padding: string;
}
const App: React.SFC<IAppProps> = props => {
  const { mainTitle, subTitle, backImageUrl, cardList, padding } = props;
  return (
    <div className={`${style.app}`}>
      <div className={style.wrapper}>
        <div
          style={{
            background: `url(${backImageUrl}) center 0px / 960px 474px no-repeat`,
            padding: padding,
          }}
        >
          {mainTitle && <h1 className={style.mainTitle}>{mainTitle}</h1>}
          {subTitle && <p className={style.subTitle}>{subTitle}</p>}
          {cardList && (
            <ul className={style.cardList}>
              {cardList.map((item, index: number) => (
                <li key={index} className={style.card}>
                  <div>
                    <img className={style.cardImage} alt="" src={item.iconImageUrl} />
                    <h2 className={style.cardMainTitle}>{item.mainTitle}</h2>
                    <p className={style.cardDesc}>{item.desc}</p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default App;
