const data: any = {
  func: {
    mainTitle: "接入流程",
    flexUrl: "https://jos-console.jd.com/#/app/index",
    getData: [
      {
        flowTitle: "注册成为开发者",
        flowIcon:
          "//img12.360buyimg.com/imagetools/s80x80_jfs/t1/140932/19/8436/1506/5f6098ceEc1163607/73e3e54b9a3ee0f6.png!q80",
        flowUrl: "http://jos.jd.com/commondoc?listId=298",
        flowColor: "#227fff",
      },
      {
        flowTitle: "创建应用",
        flowIcon:
          "//img11.360buyimg.com/imagetools/s80x80_jfs/t1/149522/38/8455/1084/5f609903Ee87a7729/66b73fcb2ed2cd02.png!q80",
        flowUrl: "http://jos.jd.com/commondoc?listId=160",
        flowColor: "#664bf4",
      },
      {
        flowTitle: "开发测试",
        flowIcon:
          "//img14.360buyimg.com/imagetools/s80x80_jfs/t1/128096/29/12580/2896/5f60995aE0f2f18d4/9b4074f5a83ce6d4.png!q80",
        flowUrl: "http://jos.jd.com/commondoc?listId=33",
        flowColor: "#664bf4",
      },
      {
        flowTitle: "发布上线",
        flowIcon:
          "//img11.360buyimg.com/imagetools/s80x80_jfs/t1/132190/17/10072/1950/5f609997Eacc1b630/6cd2b0d5dc00e075.png!q80",
        flowUrl: "https://jos-console.jd.com/#/app/index",
        flowColor: "#3896ff",
      },
    ],
  },
  style: {
    backImageUrl: "https://storage.360buyimg.com/imgtools/cc0c451537-b84d74e0-f33d-11ea-b8a5-af5361ec2d0b.png",
    backColor: "#ffffff",
  },
  data: {},
  _clstag_: "jdzeus_homepage_zeus_flow",
};
export default data;
