const data: any = {
  func: {
    mainTitle: "解决方案",
    subTitle: "",
    tabList: [
      {
        tabTitle: "云交易",
        tabContent: {
          title: {
            icon:
              "//img13.360buyimg.com/imagetools/s52x52_jfs/t1/125740/26/12971/1305/5f65b32cE45f384e0/86e9b0a72445dcf8.png!q80",
            text: "云交易-买断模式",
          },
          desc:
            "通过使用宙斯服务能力，将商家外渠道订单转化为京东自营/POP订单，进行物权的转移与履约，从而实现以销定采；适用于有自带流量、能够实现交易闭环且缺乏商品供应链，希望能够实现流量变现的用户。",
          imageList: [
            {
              picture:
                "//img12.360buyimg.com/imagetools/s80x80_jfs/t1/148257/13/11380/71793/5f8daa81E806eff49/74be5c9c8b540b65.png!q30",
            },
            {
              picture:
                "//img13.360buyimg.com/imagetools/s80x80_jfs/t1/140707/5/11672/120840/5f91852bEc762239b/156960680caf6691.png!q80",
            },
          ],
          btn: { text: "查看详情", link: "http://jos.jd.com/cloudtrade" },
        },
      },
      {
        tabTitle: "京东互联",
        tabContent: {
          title: {
            icon:
              "//img13.360buyimg.com/imagetools/s52x52_jfs/t1/125740/26/12971/1305/5f65b32cE45f384e0/86e9b0a72445dcf8.png!q80",
            text: "借助授权登录获取用户信息，实现京东用户登录第三方应用诉求。",
          },
          desc:
            "京东互联是基于OAuth2.0协议让京东用户使用京东身份安全登录第三方应用的服务, 在京东用户授权登录已接入京东OAuth2.0的第三方应用后, 第三方可以获取到用户的接口调用凭证(access_token), 通过调用凭证可以进行宙斯授权关系接口调用, 从而实现获取京东用户基本信息。",
          imageList: [
            {
              picture:
                "//img11.360buyimg.com/imagetools/s80x80_jfs/t1/151873/16/2812/71793/5f8dca88E51f1bef9/e9c147ba38de5184.png!q80",
            },
            {
              picture:
                "//img10.360buyimg.com/imagetools/s80x80_jfs/t1/133935/5/13317/115902/5f91855fE7ebf883d/878b54f79d8e3ab0.png!q80",
            },
          ],
          btn: { text: "查看详情", link: "http://jos.jd.com/jdplusmember" },
        },
      },
      {
        tabTitle: "京东健康",
        tabContent: {
          title: {
            icon:
              "//img13.360buyimg.com/imagetools/s52x52_jfs/t1/125740/26/12971/1305/5f65b32cE45f384e0/86e9b0a72445dcf8.png!q80",
            text: "帮助有需要的企业快速接入京东免费问诊服务",
          },
          desc:
            "宙斯联合京东健康发布抗击肺炎企业服务解决方案，帮助有需要的企业快速接入京东免费问诊服务，企业通过在自己的公众号、订阅号、APP等上内嵌健康的免费问诊H5服务，即可为员工提供免费问诊功能。在京东健康的在线问诊平台上，共有超过2000位医生提供免费在线咨询服务，其中包括京东健康的全职医生，他们每天8-24点不间断轮岗，以确保能够及时为用户提供专业服务。",
          imageList: [
            {
              picture:
                "//img13.360buyimg.com/imagetools/s80x80_jfs/t1/124107/36/15793/32041/5f90245dE0867bfbe/78d92b75b6cce10d.png!q80",
            },
          ],
          btn: { text: "查看详情", link: "http://jos.jd.com/jdhealth" },
        },
      },
      {
        tabTitle: "京东小程序",
        tabContent: {
          title: {
            icon:
              "//img10.360buyimg.com/imagetools/s52x52_jfs/t1/125740/26/12971/1305/5f65b32cE45f384e0/86e9b0a72445dcf8.png!q80",
            text: "分享京东系APP流量红利、海量SKU全面开放",
          },
          desc:
            "京东小程序为开发者提供一种快速开发方式，连接线上线下购物能力，帮助商家、开发者以全新的方式连接消费者。京东小程序是一种全新的开放模式，在手机京东APP上使用，可以被便捷地获取和传播，为终端用户提供更好的使用体验。京东小程序开发者平台为用户提供创建小程序、小程序开发管理、成员管理等功能。",
          imageList: [
            {
              picture:
                "//img12.360buyimg.com/imagetools/s80x80_jfs/t1/122212/8/15734/773675/5f901c5aEefb154bc/89b98fb36e532a50.png!q80",
            },
          ],
          btn: { text: "查看详情", link: "http://jos.jd.com/jdmp" },
        },
      },
    ],
  },
  style: {
    backImage:
      "//img12.360buyimg.com/imagetools/s200x200_jfs/t1/147840/7/10440/1514760/5f81b825E5ff902b7/209875b19d1781aa.png!q80",
  },
  data: {},
  _clstag_: "jdzeus_homepage_zeus_homepage_tab_container",
};
export default data;
