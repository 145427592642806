const data: any = {
  func: {
    bannerArr: [
      {
        imgUrl:
          "https://img11.360buyimg.com/imagetools/jfs/t1/152395/21/8263/691/5fc9c03aE07b286b3/7a66bbf6a3a33a24.png",
        linkUrl: "https://jimi3-chat.jd.com/bot?venderId=1597284776012",
        imgTitle: "点我吧～",
        text: "智能客服",
        backColor: "#664BF4",
        hoverColor: "#7E65FF",
        bottomDis: "82px",
      },
      {
        imgUrl:
          "https://img11.360buyimg.com/imagetools/jfs/t1/156317/13/911/1484/5fdc6e57E5dfb4cb2/279e0a0cc96b382e.png",
        linkUrl: "https://get.jd.com/#/survey/index?id=42747",
        imgTitle: "点我吧2～",
        text: "调查问卷",
        backColor: "#227FFF",
        hoverColor: "#4392FF",
        bottomDis: "132px",
      },
    ],
  },
  style: { backColor: "", bannerWidth: "48px", bannerHeight: "48px", rightDis: "30px" },
  data: {},
  _clstag_: "jdzeus_commonnav_zeus_homepage_float_rightbar",
};
export default data;
