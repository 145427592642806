import React, { useState, Fragment, useEffect } from "react";
import jsonp from "jsonp";
import style from "./index.module.scss";
import styled from "styled-components";
import ClassNames from "classnames";
import "./index.scss";

import MobileNav from "../MobileNav";
import { Data, Func, Style } from "../../config";

interface App extends Func, Data, Style {
  portal_jos_nav: any;
}

const App: React.SFC<App> = (props) => {
  const NavItem = styled.li`
    flex-shrink: 0;
    position: relative;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    color: #ffffff;
    padding: 0 12px;
    height: 100%;
    z-index: 10;
    cursor: default;
    &::before {
      content: "";
      position: absolute;
      bottom: 11px;
      left: 50%;
      margin-left: -10px;
      width: 20px;
      height: 2px;
      display: ${(props: { active: boolean }) => (props.active ? "block" : "none")};
    }
    &:hover {
      color: #7eb4ff;
    }
  `;

  const SubContainer = styled.div`
    position: absolute;
    z-index: 100;
    top: 51px; // 58px
    left: 0;
    width: ${(props: { moveLeft: number; subMenuDepth: number }) =>
      props.subMenuDepth === 1 ? "226px" : props.subMenuDepth === 2 ? "484px" : "0px"};
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    // &:before {
    //   transform: rotate(45deg);
    //   content: " ";
    //   display: block;
    //   width: 10px;
    //   height: 10px;
    //   position: absolute;
    //   left: ${(props: { moveLeft: number; subMenuDepth: number }) => props.moveLeft + "px"};
    //   top: -6px;
    //   background: #ffffff;
    //   border-left: 1px solid #eaeaea;
    //   border-top: 1px solid #eaeaea;
    //   box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    // }
  `;

  const TipContainer = styled.div`
    position: absolute;
    top: 64px;
    right: ${(props: { moveRight: number; width: number; right: number }) => props.right + "px"};
    width: ${(props: { moveRight: number; width: number }) => props.width + "px"};
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &:before {
      transform: rotate(45deg);
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      right: ${(props: { moveRight: number; width: number }) => props.moveRight + "px"};
      top: -6px;
      background: #ffffff;
      border-left: 1px solid #eaeaea;
      border-top: 1px solid #eaeaea;
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .TipsItem {
      padding: 12px 18px;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 24px;
      letter-spacing: 0px;
      color: #393939;
    }
    .TipsItem.red {
      color: #e20000;
    }
    .TipsItem:hover {
      background-color: rgba(34, 127, 255, 0.08);
      color: red;
    }
  `;
  let timer = null;
  const {
    logo,
    // activeColor,
    // bgColor,
    menuContent,
    // subMenu,
    portal_jos_nav: portalJosNav,
    subMenuContent,
    // showLogin,
    autoRedirectLogin,
  } = props;
  const [loginData, setLoginData] = useState({ Name: "" });
  const [subMenuKey, setSubMenuKey] = useState("");
  const [subMenuData, setSubMenuData] = useState(null);
  const [loginTips, setLoginTips] = useState(false);
  const [subMenuTwoIndex, setSubMenuTwoIndex] = useState(0);
  const [subMenuDepth, setSubMenuDepth] = useState(0);
  const [subMenuDom, setSubMenuDom] = useState({});
  const [menuBar, setMenuBar] = useState(false);
  const [menuNav, setMenuNav] = useState(false);
  const [miniAvatarIcon, setMiniAvatarIcon] = useState(false);
  const [scrollTopHeight, setScrollTopHeight] = useState(0);
  const subMenuContentTemp = {};
  subMenuContent.map((item: { key: string; data: {} }) => {
    subMenuContentTemp[item.key] = item.data;
  });
  const {
    data: { data },
  } = portalJosNav;
  const allNavMenu = Object.assign(
    {},
    subMenuContentTemp,
    portalJosNav && portalJosNav.data && portalJosNav.data.data ? portalJosNav.data.data : {},
  );
  // const loginData = index_login.code === 200 ? index_login.data : {};

  const maxScrollHeight = 500;
  const handleMouseScroll = () => {
    const scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
    setScrollTopHeight(scrolltop);
  };

  const logout = () => {
    (window as any).josLogout = () => {
      window.location.href = "/";
    };
    jsonp("//sso.jd.com/exit?callback=josLogout", {});
  };

  useEffect(() => {
    document.addEventListener("scroll", handleMouseScroll, true);
    jsonp("//passport.jd.com/loginservice.aspx?method=Login", {}, (err, res) => {
      console.log(err);
      if ((!res || (res && !res.Identity) || (res && res.Identity && !res.Identity.Name)) && autoRedirectLogin) {
        window.location.href = "//passport.jd.com/new/login.aspx?ReturnUrl=" + encodeURIComponent(window.location.href);
        return;
      }
      setLoginData(res.Identity);
    });

    // fetch("//passport.jd.com/loginservice.aspx?method=Login&callback=?",{
    //   credentials: 'include'
    // })
    //   .then((res) => {
    //     console.log(res);
    //     res.json();
    //   })
    //   .then((res) => {
    //     if (res.code === 200) {
    //       setLoginData(res.data);
    //     }
    //     console.log(res);
    //   });

    // return () => {
    //   document.removeEventListener("scroll", () => {}, true);
    // };
  }, []);

  return (
    <div
      className={`${style.app}`}
      style={{
        background: scrollTopHeight < maxScrollHeight ? "rgb(20,22,33)" : "rgb(20,22,33,.8)",
        position: window.location.pathname.includes("/home.html") ? "fixed" : "static",
      }}
    >
      <div
        onClick={() => (window.location.href = `//jos.jd.com`)}
        className={style.logo}
        style={{
          background: `url(${logo.link}) no-repeat 0 0 / 100% 100%`,
          width: logo.width,
          height: logo.height,
          cursor: "pointer",
        }}
      >
        {logo.link ? "" : logo.text}
      </div>
      <ul className={style.nav} style={{ marginBottom: 0 }}>
        {menuContent &&
          menuContent.map((item, index) => (
            <NavItem
              ref={(node) => {
                setSubMenuDom(
                  Object.assign(subMenuDom, {
                    [item.key]: node,
                  }),
                );
              }}
              key={`navitem_${index}`}
              active={item.active}
              onClick={() => {
                if (item.key === "JDATA" || item.key === "console") {
                  window.open(item.url);
                } else {
                  if (item.url !== "#menu") {
                    window.open(item.url);
                  }
                }
              }}
              onMouseEnter={() => {
                clearTimeout(timer);
                setSubMenuKey("");
                let depth = 0;
                const loopGetLevel = (obj, level = 1) => {
                  if (typeof obj === "object") {
                    for (const key in obj) {
                      if (typeof obj[key] === "object") {
                        loopGetLevel(obj[key], level + 1);
                      } else {
                        depth = level > depth ? level : depth;
                      }
                    }
                  } else {
                    depth = level > depth ? level : depth;
                  }
                };

                if (item.url === "#menu") {
                  // console.dir(subMenuDom[item.key])
                  // subMenuDom[item.key].offsetWidth / 2
                  loopGetLevel(allNavMenu[item.key]);
                  // console.log(depth); // 2  4
                  setSubMenuKey(item.key);
                  setSubMenuData(allNavMenu[item.key]);
                  if (depth === 2) {
                    // 一级菜单
                    setSubMenuDepth(1);
                  } else {
                    // 二级菜单或更多
                    setSubMenuDepth(2);
                  }
                } else {
                  setSubMenuData(null);
                }
              }}
              onMouseLeave={() => {
                timer = setTimeout(() => {
                  setSubMenuKey("");
                }, 300);
              }}
            >
              {item.name}
              {item.url === "#menu" ? (
                <img
                  src={
                    "https://img12.360buyimg.com/imagetools/jfs/t1/131114/18/6952/341/5f34f598Eaa4dd364/f8e2500f1aab72ab.png"
                  }
                  height={22}
                  width={8}
                  style={{ marginLeft: 4 }}
                  alt=""
                />
              ) : (
                ""
              )}
              {subMenuKey === item.key && subMenuData && (
                <SubContainer
                  moveLeft={subMenuDom[subMenuKey] && subMenuDom[subMenuKey].offsetWidth / 2}
                  subMenuDepth={subMenuDepth}
                >
                  {subMenuDepth === 1 &&
                    subMenuData &&
                    subMenuData.map((item, index) => {
                      return (
                        <div
                          className={style.subMenuOne}
                          onClick={() => {
                            if (item.url) {
                              window.open(item.url);
                            }
                          }}
                          key={`submenu_1_${index}`}
                        >
                          {item.name}
                        </div>
                      );
                    })}

                  {subMenuDepth === 2 && subMenuData && (
                    <div className={style.subMenuTwo}>
                      <div className={style.subMenuTwoLeft}>
                        {subMenuData &&
                          subMenuData.map((item, index) => {
                            return (
                              <div
                                className={ClassNames([
                                  style.subMenuTwoItem,
                                  {
                                    [style.subMenuTwoItemHover]: index === subMenuTwoIndex,
                                  },
                                ])}
                                key={`submenu_1_${index}`}
                                onMouseEnter={() => {
                                  setSubMenuTwoIndex(index);
                                }}
                              >
                                {item.name}
                              </div>
                            );
                          })}
                      </div>
                      <div className={style.subMenuTwoRight}>
                        {subMenuData &&
                          subMenuData[subMenuTwoIndex] &&
                          subMenuData[subMenuTwoIndex].child &&
                          subMenuData[subMenuTwoIndex].child.map((item, index) => {
                            return (
                              <div
                                className={style.subMenuTwoContent}
                                key={`submenu_2_${index}`}
                                onClick={() => {
                                  if (item.url) {
                                    window.open(item.url);
                                  }
                                }}
                              >
                                <div>{item.name}</div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </SubContainer>
              )}
            </NavItem>
          ))}
      </ul>
      {/*大屏幕 start */}
      {loginData && loginData.Name ? (
        <div
          className={style.login}
          onMouseEnter={() => {
            clearTimeout(timer);
            setLoginTips(true);
          }}
          onMouseLeave={() => {
            timer = setTimeout(() => {
              setLoginTips(false);
            }, 300);
          }}
        >
          <div className={style.avatarCon}>
            <img
              src="https://img13.360buyimg.com/imagetools/jfs/t1/31286/28/11077/1973/5cb3e9f9Eaea79b8b/3aef42a6c6879eed.png"
              className={style.avatar}
              alt=""
            />
          </div>

          <div className={style.textCon}>
            <div className={style.textTop}>欢迎您</div>
            <div className={style.textName}>{loginData.Name}</div>
          </div>
          <div className={style.arrow} />

          {loginTips && (
            <TipContainer width={226} moveRight={20} right={1}>
              <div
                className="TipsItem"
                onClick={() => {
                  window.open("/authmanagement");
                }}
              >
                授权管理
              </div>
              <div className="TipsItem red" onClick={logout}>
                退出登录
              </div>
            </TipContainer>
          )}
        </div>
      ) : (
        <div className={style.login}>
          <span
            onClick={() => {
              window.location.href =
                "//passport.jd.com/new/login.aspx?ReturnUrl=" + encodeURIComponent(window.location.href);
            }}
            className={style.loginImmediate}
          >
            立即登录
          </span>
          <span
            onClick={() => {
              window.location.href = "//reg.jd.com/p/regPage?ignore=1";
            }}
            className={style.sign}
          >
            注册账号
          </span>
        </div>
      )}
      {/*大屏幕 end */}

      {/*小屏幕 start */}
      <div className={style.minScreenLogin}>
        {/*<img
          className={ClassNames([
            style.avatarIcon,
            {
              [style.avatarHide]: miniAvatarIcon
            }
          ])}
          onClick={() => {
            if (!miniAvatarIcon) {
              // 隐藏导航
              setMenuNav(false);
              // 显示登陆信息
              setMiniAvatarIcon(true);
              // 修改bar状态
              setMenuBar(true);
            }
          }}
          src="//img11.360buyimg.com/imagetools/jfs/t1/120375/29/5202/2561/5eeb0e94E9a7c1245/7910c13502322b66.png"
          alt=""
        />*/}

        <div
          className={ClassNames([
            "header_menu_bar",
            {
              actived: menuBar,
            },
          ])}
          onClick={() => {
            if (menuBar) {
              setMenuNav(false);
              setMiniAvatarIcon(false);
              setMenuBar(false);
            } else {
              setMiniAvatarIcon(false);
              setMenuNav(true);
              setMenuBar(true);
            }
          }}
        >
          <span />
          <span />
          <span />
        </div>
        {miniAvatarIcon && (
          <div className="header_login_status">
            {loginData && (
              <Fragment>
                <div className="header_login_status-top">
                  <div className="header_login_status-info">
                    <div className="header_login_status-avatar">
                      <img
                        src="https://img13.360buyimg.com/imagetools/jfs/t1/31286/28/11077/1973/5cb3e9f9Eaea79b8b/3aef42a6c6879eed.png"
                        alt=""
                      />
                    </div>
                    <div className="header_login_status-text">
                      <div className="header_login_status-default">欢迎您</div>
                      <div className="header_login_status-name">柳宗元</div>
                    </div>
                  </div>
                  <i className="header_login_status-arrow" />
                </div>
                <div className="header_login_status-bottom">退出登录</div>
              </Fragment>
            )}

            {!loginData && (
              <div className="header_login_status-btn">
                <div className="header_login_status-register">免费注册</div>
                <div className="header_login_status-in">登陆</div>
              </div>
            )}
          </div>
        )}

        {menuNav && (
          <div className="header_login_status">
            <MobileNav menuContent={menuContent} subMenu={allNavMenu} />
          </div>
        )}
      </div>
      {/*小屏幕 end */}

      {menuNav && <div className="fixBanner"></div>}
    </div>
  );
};

export default App;
