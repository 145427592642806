import React, { useRef, useEffect } from "react";
import style from "./index.module.scss";
import styled from "styled-components";
import Slider from "react-slick";
import "./reset_slider_new.scss";
import * as Type from "../../config";

const a = 1;

export interface IAppProps {
  mainTitle: string;
  subTitle: string;
  rotationData: RotationDatum[];
  rotationSetting: {
    autoplay: boolean;
  };
}

export interface RotationDatum {
  name: string;
  url: string;
  article: {
    mainTitle: string;
    subTitle: string;
    picture: string;
    desc: {
      icon: string;
      title: string;
      text: string;
    };
    backImage: string;
    btn: {
      text: string;
      link: string;
      icon: string;
    };
  };
}

interface ISliderItemProps {
  bgImgUrl: string;
}

const App: React.SFC<IAppProps> = (props) => {
  console.log(props);
  const { mainTitle, subTitle, rotationData, rotationSetting, backImage } = props;

  let SliderItem = styled.div`
    position: relative;
  `;

  const settings = {
    ...{
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    },
    ...rotationSetting,
  };
  const videoRef = useRef();

  useEffect(() => {
    videoRef && videoRef.current && videoRef.current.addEventListener("play", () => {});
  });
  return (
    <div className={`${style.zeusHomepageSuccessCase} zeusHomepageSuccessCase`}>
      <img className={style.backImage} src={backImage} alt="" onClick={() => {}} />
      <div className={style.titleWrapper}>
        <h1 className={style.mainTitle}>{mainTitle}</h1>
        <h2 className={style.subTitle}>{subTitle}</h2>
      </div>
      <Slider {...settings}>
        {rotationData.map((item, index) => {
          return (
            <SliderItem
              // onClick={() => {
              //   window.location.href = item.url;
              // }}
              key={`slider_${index}`}
            >
              <div
                style={{
                  position: "relative",
                  height: "446px",
                }}
              >
                <div className={style.contentWrapper}>
                  <div className={style.content}>
                    {item.article && item.article.picture && (
                      <div className={style.left}>
                        <img src={item.article.picture} alt="" />
                      </div>
                    )}
                    {item.article && item.article.mainTitle && (
                      <div className={style.right}>
                        {item.article.mainTitle && <h3 className={style.mainTitle}>{item.article.mainTitle}</h3>}
                        {item.article.subTitle && <p className={style.subTitle}>{item.article.subTitle}</p>}
                        {item.article.desc && item.article.desc.title && (
                          <div className={style.desc}>
                            <p className={style.title}>
                              <em
                                style={{
                                  background: `url(${item.article.desc.icon})`,
                                }}
                                className={style.icon}
                              />
                              <span>{item.article.desc.title}</span>
                            </p>
                            <p className={style.text}>{item.article.desc.text}</p>
                          </div>
                        )}
                        <button
                          className={style.btn}
                          onClick={() => {
                            window.open(item.article.btn.link)
                          }}
                        >
                          {item.article.btn.text}
                          <i
                            className={style.rightArrow}
                            style={{ background: `url(${item.article.btn.icon}) no-repeat 0 0 / cover` }}
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </SliderItem>
          );
        })}
      </Slider>
    </div>
  );
};

export default App;
