import React, { useState } from "react";
import style from "./index.module.scss";
import styled from "styled-components";
import * as Type from "../../config";
export interface IAppProps {
  mainTitle: string;
  subTitle: string;
  backImage: string;
  tabList: [
    {
      tabTitle: string;
      tabContent: {
        title: {
          icon: string;
          text: string;
        };
        desc: string;
        imageList: [
          {
            picture: string;
          },
        ];
        btn: {
          text: string;
          link: string;
        };
      };
    },
  ];
}
const App: React.SFC<IAppProps> = (props) => {
  console.log(props);
  const { mainTitle, subTitle, tabList, backImage } = props;
  const StyledFloor = styled.div`
    margin: 0 auto;
    padding-top: 86px;
    padding-bottom: 72px;
  `;
  const [active, setActive] = useState(0);
  console.log(tabList);
  console.log(tabList[active]);
  return (
    <StyledFloor
      className={`${style.floor}`}
      style={{ background: `rgba(244,247,250,1) url(${backImage}) 0px 0px / cover no-repeat` }}
    >
      <div>
        <h1 className={style.mainTitle}>{mainTitle}</h1>
        <h2 className={style.subTitle}>{subTitle}</h2>
      </div>
      <div className={style.tabContainerWrapper}>
        <div className={style.tabContainer}>
          <div className={style.tabTitleContainerWrapper}>
            <ul className={style.tabTitleContainer}>
              {tabList.map((item, index) => (
                <li
                  key={`tab_title_${index}`}
                  onClick={() => {
                    setActive(index);
                  }}
                  className={`${style.tabTitle} ${index === active && style.tabTitleActive}`}
                >
                  {item.tabTitle}
                </li>
              ))}
            </ul>
          </div>

          {tabList[active] && tabList[active].tabContent && (
            <div className={style.tabContent}>
              <div className={style.titleWrapper}>
                <em
                  className={style.icon}
                  style={{ background: `url(${tabList[active].tabContent.title.icon}) no-repeat 0 0 / cover` }}
                />
                <h2 className={style.text}>{tabList[active].tabContent.title.text}</h2>
              </div>
              <p className={style.desc}>{tabList[active].tabContent.desc}</p>
              {tabList[active].tabContent.imageList && (
                <ul className={style.imageList}>
                  {tabList[active].tabContent.imageList.map((item, index) => (
                    <li key={index}>
                      <img src={item.picture} alt="" />
                    </li>
                  ))}

                  {(tabList[active].tabContent.imageList.length) % 3 !== 0 &&
                  Array(tabList[active].tabContent.imageList.length < 3 ? 3 - (tabList[active].tabContent.imageList.length) : 3 - ((tabList[active].tabContent.imageList.length) % 3))
                    .fill("")
                    .map((v, k) => {
                      return <li style={{ visibility: "hidden" }} key={k + v} />;
                    })}
                </ul>
              )}
              <button
                className={style.btn}
                onClick={() => {
                  window.open(tabList[active].tabContent.btn.link)
                }}
              >
                {tabList[active].tabContent.btn.text}
                <i
                  className={style.rightArrow}
                  style={{ background: `url(//img13.360buyimg.com/imagetools/s14x44_jfs/t1/138661/33/8881/489/5f659e25Ecbeab049/576d59406105c838.png) no-repeat 0 0 / cover` }}
                />
              </button>
            </div>
          )}
        </div>
      </div>

      {/*<div className={style.mobile}>*/}
      {/*  <ul className={style.mobileTabTitleCon}>*/}
      {/*    {tabContent.map((li, index) => (*/}
      {/*      <li*/}
      {/*        key={`tab_title_${index}`}*/}
      {/*        onClick={() => {*/}
      {/*          setActive(index);*/}
      {/*        }}*/}
      {/*        className={`${style.mobileTabTitle} ${index === active ? style.unfold : style.fold} `}*/}
      {/*      >*/}
      {/*        <h1> {li.title.text}</h1>*/}
      {/*        <div className={style.tabContent}>*/}
      {/*          <ul>*/}
      {/*            {tabContent[active].content.map((item, index) => (*/}
      {/*              <li>*/}
      {/*                <h2>{item.mainTitle}</h2>*/}
      {/*                <div className={style.iconWrapper}>*/}
      {/*                  <p key={index}>*/}
      {/*                    <em*/}
      {/*                      style={{*/}
      {/*                        background: `url(${item.icon}) no-repeat 0 0 / 100% 100%`,*/}
      {/*                      }}*/}
      {/*                    />*/}
      {/*                    <span className={style.desc}>{item.desc}</span>*/}
      {/*                  </p>*/}
      {/*                </div>*/}
      {/*              </li>*/}
      {/*            ))}*/}
      {/*          </ul>*/}
      {/*        </div>*/}
      {/*      </li>*/}
      {/*    ))}*/}
      {/*  </ul>*/}
      {/*</div>*/}
    </StyledFloor>
  );
};

export default App;
