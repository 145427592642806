import React, { useRef, useEffect } from "react";
import style from "./index.module.scss";
import styled from "styled-components";
import Slider from "react-slick";
import "./reset_slider.scss";

export interface IAppProps {
  mainTitle: string;
  subTitle: string;
  contactMeBtn: string;
  contactMe: string;
  rotationData: RotationDatum[];
  rotationSetting: {
    autoplay: boolean;
  };
}

export interface RotationDatum {
  name: string;
  img: string;
  url: string;
}

interface ISliderItemProps {
  bgImgUrl: string;
}

const App: React.SFC<IAppProps> = props => {
  const { rotationData, rotationSetting } = props;

  const SliderItem = styled.div`
    position: relative;
    img {
      width: 100%;
      height: 580px;
      object-fit: cover;
      object-position: center;
    }
  `;

  const settings = {
    ...{
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    },
    ...rotationSetting,
  };
  const videoRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //@ts-ignore
    videoRef && videoRef.current && videoRef.current.addEventListener("play", null);
  });
  return (
    <div className={`${style.app} zeusHomepageRotation`}>
      <Slider
        {...settings}
        style={{
          height: " 580px",
        }}
      >
        {rotationData.map((value: any, index) => {
          return (
            <SliderItem
              // onClick={() => {
              //   window.location.href = value.url;
              // }}
              className={"sliderItem"}
              key={`slider_${index}`}
            >
              <img
                src={value.img}
                alt=""
                onClick={() => {
                  window.open(value.url);
                }}
              />
              <div className={style.content}>
                <div className={style.left}>
                  <h4 className={style.main}>{value.mainTitle}</h4>
                  <h6 className={style.sub}>{value.subTitle}</h6>
                  <button
                    className={style.btn}
                    onClick={() => {
                      window.open(value.url);
                    }}
                  >
                    {value.contactMeBtn}
                    <i className={style.rightArrow} />
                  </button>
                </div>
                {value.video && value.video[0] && value.video[0].link && (
                  <div className={style.right}>
                    <div className={style.videoWrapper}>
                      <video controls poster={value.video[0].poster} ref={videoRef}>
                        <source src={value.video[0].link} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                )}
              </div>
            </SliderItem>
          );
        })}
      </Slider>
    </div>
  );
};

export default App;
