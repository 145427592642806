const data: any = {
  func: {
    mainTitle: "能力地图",
    subTitle: "京东零售能力开放，打造可持续化商业生态",
    getData: [
      {
        mapTitle: "物流能力",
        imgSrc:
          "//img10.360buyimg.com/imagetools/s424x384_jfs/t1/133781/28/10069/24322/5f617d3eE98906df2/c2469678245bee83.png!q40",
        mapPositionX: 0,
        mapPositionY: 168,
        mapList: [
          {
            tagTitle: "无界电子面单",
            tagPositionX: 30,
            tagPositionY: 60,
            tagUrl: "https://jos.jd.com/commondoc?listId=335",
          },
          {
            tagTitle: "京东药急送",
            tagPositionX: 0,
            tagPositionY: 104,
            tagUrl: "https://jos.jd.com/commondoc?listId=79",
          },
          {
            tagTitle: "众邮快递",
            tagPositionX: 50,
            tagPositionY: 145,
            tagUrl: "https://jos.jd.com/commondoc?listId=326",
          },
          {
            tagTitle: "POP面单打印",
            tagPositionX: 264,
            tagPositionY: 94,
            tagUrl: "https://jos.jd.com/commondoc?listId=336",
          },
          {
            tagTitle: "京东配送服务",
            tagPositionX: 245,
            tagPositionY: 150,
            tagUrl: "https://jos.jd.com/commondoc?listId=72",
          },
        ],
      },
      {
        mapTitle: "营销能力",
        imgSrc:
          "//img11.360buyimg.com/imagetools/s424x384_jfs/t1/119106/39/18053/49127/5f617d4aEf5a24eef/3dfd413de62b2bf0.png!q80",
        mapPositionX: 405,
        mapPositionY: 310,
        mapList: [
          {
            tagTitle: "优惠券",
            tagPositionX: 27,
            tagPositionY: 112,
            tagUrl: "https://jos.jd.com/commondoc?listId=334",
          },
          {
            tagTitle: "营销插件",
            tagPositionX: 42,
            tagPositionY: 155,
            tagUrl: "https://jos.jd.com/commondoc?listId=13",
          },
          {
            tagTitle: "用户标签",
            tagPositionX: 238,
            tagPositionY: 95,
            tagUrl: "https://jos.jd.com/commondoc?listId=75",
          },
          {
            tagTitle: "品牌会员",
            tagPositionX: 260,
            tagPositionY: 150,
            tagUrl: "https://jos.jd.com/commondoc?listId=16",
          },
        ],
      },
      {
        mapTitle: "云服务能力",
        imgSrc:
          "//img13.360buyimg.com/imagetools/s424x384_jfs/t1/121935/15/12829/23424/5f617d57Ee419adf1/273f471b177980d9.png!q80",
        mapPositionX: 490,
        mapPositionY: 19,
        mapList: [
          { tagTitle: "JAS工作站", tagPositionX: 250, tagPositionY: 112, tagUrl: "https://jas.jd.com/" },
          {
            tagTitle: "云鼎服务",
            tagPositionX: 25,
            tagPositionY: 112,
            tagUrl: "https://jos.jd.com/commondoc?listId=69",
          },
        ],
      },
      {
        mapTitle: "基础能力",
        imgSrc:
          "//img12.360buyimg.com/imagetools/s424x384_jfs/t1/122945/13/12926/29583/5f617d62E6bf65af2/a9e71be0c1cc211f.png!q80",
        mapPositionX: 865,
        mapPositionY: 113,
        mapList: [
          {
            tagTitle: "授权登录",
            tagPositionX: 256,
            tagPositionY: 90,
            tagUrl: "https://jos.jd.com/commondoc?listId=32",
          },
          {
            tagTitle: "API测试工具",
            tagPositionX: 262,
            tagPositionY: 124,
            tagUrl: "https://jos.jd.com/commondoc?listId=338",
          },
          {
            tagTitle: "多语言SDK",
            tagPositionX: 245,
            tagPositionY: 160,
            tagUrl: "https://jos.jd.com/commondoc?listId=168",
          },
          {
            tagTitle: "应用管理",
            tagPositionX: 40,
            tagPositionY: 158,
            tagUrl: "https://jos-console.jd.com/#/app/index",
          },
          {
            tagTitle: "小程序组件库",
            tagPositionX: 15,
            tagPositionY: 120,
            tagUrl: "https://mp.jd.com/docs/dev/component/",
          },
          {
            tagTitle: "多场景API",
            tagPositionX: 15,
            tagPositionY: 80,
            tagUrl: "https://jos.jd.com/apilist?apiGroupId=106&apiId=13222&apiName=jingdong.getUserLevel.query",
          },
        ],
      },
    ],
  },
  style: {
    backImageUrl:
      "//img14.360buyimg.com/imagetools/s2880x940_jfs/t1/146794/33/8340/96427/5f5f1d1dE645b4ac0/168a61f958d2b90b.png!q80",
  },
  data: {},
  _clstag_: "jdzeus_homepage_zeus_map",
};
export default data;
