const data: any = {
  func: {
    mainTitle: "成功案例",
    subTitle: "沉淀多年的京东技术能力，帮助合作伙伴搭建商业生态",
    backImage:
      "//img12.360buyimg.com/imagetools/s2880x1440_jfs/t1/123770/20/18965/144064/5fb2578dEded73be3/d61f54f637cfeddc.jpg!q40",
    rotationData: [
      {
        name: "1",
        url: "http://#none",
        article: {
          picture:
            "//img13.360buyimg.com/imagetools/s300x699_jfs/t1/121117/21/15896/1265499/5f904911E051fcdb6/2deabd3361c47381.jpg!q80",
          mainTitle: "腾讯视频",
          subTitle:
            "腾讯视频为京东重点合作伙伴，拥有流行内容和专业的媒体运营能力，是聚合热播影视、综艺娱乐、体育赛事、新闻资讯等为一体的综合视频内容平台，通过PC端、移动端等多种形态，可以为用户提供高清流畅的视频娱乐体验。",
          desc: {
            icon:
              "//img11.360buyimg.com/imagetools/s18x18_jfs/t1/142429/23/8873/819/5f657660E11c8d589/e60f28012b79a4a1.png!q80",
            title: "方案介绍",
            text:
              "京东为了能够为广大用户提供更优质的购物体验，特别推出京东PLUS联合会员服务，权益包括购物回馈、自营免运费、退换无忧、专属客服等，腾讯视频通过与京东PLUS会员打通，极大的丰富了其会员权益，不仅提升了用户体验，还获得了大量的注册会员，大幅度提升了自身业绩。",
          },
          btn: {
            text: "查看详情",
            link: "https://jos.jd.com/jdplusmember",
            icon:
              "//img11.360buyimg.com/imagetools/s14x44_jfs/t1/138661/33/8881/489/5f659e25Ecbeab049/576d59406105c838.png!q80",
          },
        },
      },
      {
        name: "2",
        url: "http://#none",
        article: {
          picture:
            "//img10.360buyimg.com/imagetools/s300x699_jfs/t1/122403/2/15618/1484455/5f8dc96cEf1e084b4/8346721de58d2237.jpg!q80",
          mainTitle: "林氏木业",
          subTitle:
            "林氏木业，创立于2007年，佛山市承林家具有限公司旗下品牌，是集专业研发、制造、销售、售后于一体的互联网家具企业。一直以来，林氏木业以精准的大数据运营为核心，通过多风格全品类的家具产品布局、专业贴心的家居服务、轻松便捷的购物体验，为消费者创造更美好的生活方式。",
          desc: {
            icon:
              "//img10.360buyimg.com/imagetools/s18x18_jfs/t1/142429/23/8873/819/5f657660E11c8d589/e60f28012b79a4a1.png!q80",
            title: "方案介绍",
            text:
              "通过使用宙斯服务能力，实现了系统的自动派单与物流信息的同步跟踪，减少了15%以上的售后物流咨询量，极大的减少了人力成本投入，大幅度提升了用户体验，实现了业务增长",
          },
          btn: {
            text: "查看详情",
            link: "https://jos.jd.com/houseware",
            icon:
              "//img11.360buyimg.com/imagetools/s14x44_jfs/t1/138661/33/8881/489/5f659e25Ecbeab049/576d59406105c838.png!q80",
          },
        },
      },
    ],
  },
  style: {
    rotationSetting: {
      autoplay: false,
      autoplaySpeed: 1,
      dots: true,
      arrows: true,
      infinite: false,
      fade: false,
      easing: true,
    },
    contactMeBgColor: "#227fff",
  },
  data: {},
  _clstag_: "jdzeus_homepage_zeus_homepage_success_case",
};
export default data;
