const data: any = {
  func: {
    logo: {
      text: "宙斯开发者",
      link:
        "//img12.360buyimg.com/imagetools/s200x200_jfs/t1/118697/14/19219/13380/5f8597bfEe2f1a7a6/28da4f4bd65f005c.png!q80",
      width: "208px",
      height: "30px",
    },
    menuContent: [
      { name: "开放版图", url: "#menu", key: "territory", active: false },
      { name: "解决方案", url: "#menu", key: "solution", active: false },
      { name: "能力中心", url: "/powercenter", key: "powercenter", active: false },
      { name: "文档中心", url: "#menu", key: "document", active: false },
      { name: "支持中心", url: "#menu", key: "support", active: false },
      { name: "常用工具", url: "#menu", key: "tools", active: false },
      { name: "控制中心", url: "https://jos-console.jd.com", key: "console", active: false },
    ],
    subMenuContent: [
      {
        key: "document",
        data: [
          { name: "API文档", url: "/apilistnew" },
          { name: "消息文档", url: "/msglist" },
          { name: "平台公告", url: "/platformlist" },
          { name: "常用文档", url: "/commondoc" },
        ],
      },
      {
        key: "support",
        data: [
          { name: "FAQ", url: "/faqlist" },
          { name: "工单支持", url: "/supportindex" },
        ],
      },
      {
        key: "tools",
        data: [
          { name: "授权工具", url: "/commontools?id=1" },
          { name: "签名工具", url: "/commontools?id=2" },
          { name: "权限查询工具", url: "/commontools?id=3" },
          { name: "云鼎IP查询工具", url: "/commontools?id=4" },
          { name: "Token有效期查询", url: "/commontools?id=5" },
          { name: "API日志排查工具", url: "/commontools?id=6" },
        ],
      },
    ],
    autoRedirectLogin: false,
  },
  style: { activeColor: "#227fff", bgColor: "#000000" },
  data: {
    portal_jos_nav: {
      type: "remote",
      config: {
        id: 5,
        url: "//joshome.jd.com/jos/nav",
        method: "GET",
        headers: null,
        options: null,
        dataName: "navMenu",
        reqParams: {},
        dynamicParams: {},
        filter: "return data;",
        dataRoute: "",
        // pathname: "/jos/nav",
      },
      data: {},
      localFilter: "return data;",
    },
  },
  _clstag_: "jdzeus_commonnav_zeus_homepage_header",
};
export default data;
