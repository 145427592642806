const data: any = {
  func: {
    mainTitle: "",
    copyright: "Copyright © 2004 - 2020 京东JD.com 版权所有",
    logo:
      "//img10.360buyimg.com/imagetools/s578x84_jfs/t1/134975/4/12880/21144/5f8d61d4Ef72ad8a9/fbc0eeb313b9ef57.png!q80",
    linkRight: [
      {
        title: "新手入门",
        link: [
          { text: "平台简介", link: "//jos.jd.com/commondoc" },
          { text: "接入指南", link: "//jos.jd.com/commondoc?listId=298" },
          { text: "权限申请", link: "//jos.jd.com/commondoc?listId=170" },
        ],
      },
      {
        title: "技术支持",
        link: [
          {
            text: "用户API",
            link: "/apilistnewdetail.html?first=502&second=505",
          },
          {
            text: "商品API",
            link: "/apilistnewdetail.html?first=440&second=442",
          },
          {
            text: "咚咚API",
            link: "/apilistnewdetail.html?first=335&second=336",
          },
          { text: "权限申请", link: "/commondoc.html?listId=170" },
          { text: "API调用", link: "//jos.jd.com/commondoc?listId=33" },
          { text: "SDK使用", link: "//jos.jd.com/commondoc?listId=167" },
          { text: "获取授权", link: "//jos.jd.com/commondoc?listId=32" },
        ],
      },
      {
        title: "客服帮助",
        link: [
          { text: "工单支持", link: "//jos.jd.com/supportindex" },
          { text: "FAQ", link: "//jos.jd.com/faqlist" },
        ],
      },
    ],
  },
  style: { backColor: "linear-gradient(90deg, rgba(6,10,35,1) 0%,rgba(17,24,62,1) 100%)" },
  data: {},
  _clstag_: "jdzeus_commonnav_zeus_homepage_footer",
};
export default data;
