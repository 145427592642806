const data: any = {
  func: {
    mainTitle: "宙斯开放",
    subTitle: "",
    contactMeBtn: "立即入驻",
    contactMe: "https://jos-console.jd.com/#/app/index",
    rotationData: [
      {
        name: "3",
        mainTitle: "能力中心",
        subTitle: "提供多场景优质能力，帮助开发者提升效率",
        contactMeBtn: "了解详情",
        img:
          "//img13.360buyimg.com/imagetools/s2880x1160_jfs/t1/216509/10/1342/1126002/61712918E36910f11/be372b01fa2da94d.png!q40",
        url: "https://jos.jd.com/powercenter",
        video: [
          {
            poster:
              "//img14.360buyimg.com/imagetools/s868x492_jfs/t1/152067/2/31/156755/5f61cb7dE31e6bea3/45326df3a1c17666.png!q50",
            link: "",
          },
        ],
      },
      {
        name: "1",
        mainTitle: "宙斯开发者中心全新上线",
        subTitle: "从接口开放平台升级为以开发者为中心的技术服务平台",
        contactMeBtn: "了解详情",
        img:
          "//img14.360buyimg.com/imagetools/s1800x1200_jfs/t1/149826/33/11191/268051/5f8c538cE749e96f5/a47bc0dedc7fd25b.jpg!q40",
        url: "https://jos.jd.com/commondoc?listId=128",
        video: [
          {
            poster:
              "//img14.360buyimg.com/imagetools/s868x492_jfs/t1/152067/2/31/156755/5f61cb7dE31e6bea3/45326df3a1c17666.png!q50",
            link: "",
          },
        ],
      },
      {
        name: "2222",
        mainTitle: "宙斯开发者中心",
        subTitle: "实现零售能力开放及赋能，打造可持续商业生态",
        contactMeBtn: "立即入驻",
        img:
          "//img12.360buyimg.com/imagetools/s1800x1200_jfs/t1/124716/18/15261/553801/5f8c5409E711f5094/7c2360dc3af9f641.jpg!q40",
        url: "https://jos-console.jd.com/#/app/index",
        video: [
          {
            poster:
              "//img13.360buyimg.com/imagetools/s1800x1200_jfs/t1/146097/33/11137/553801/5f8c53c8Ea1896042/867065e6dc090337.jpg!q80",
            link: "",
          },
        ],
      },
    ],
    rotationSetting: {
      autoplay: true,
      autoplaySpeed: 4000,
      dots: true,
      arrows: true,
      infinite: true,
      fade: true,
      easing: false,
    },
  },
  style: { contactMeBgColor: "#227fff" },
  data: {},
  _clstag_: "jdzeus_homepage_zeus_homepage_ratation",
};
export default data;
