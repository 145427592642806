import React, { Component, Fragment } from "react";
import ClassNames from "classnames";
import "./index.scss";

interface Props {
  menuContent: MenuContent[];
  subMenu: SubMenu;
}

export interface MenuContent {
  name: string;
  url: string;
  key: string;
  active?: boolean;
}

export interface SubMenu {
  type: {};
  data: any;
}

export interface NavDataItem {
  name: string;
  url: string;
  content?: NavDataItem[];
}

interface State {
  // 第一级菜单的key值
  firstLevelKey: string;
  // 第二级菜单name
  secondLevelName: string;
}

export default class MobileNav extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      firstLevelKey: "",
      secondLevelName: "",
    };
  }

  renderNavItem() {
    const { menuContent, subMenu } = this.props;

    const { firstLevelKey, secondLevelName } = this.state;
    return (
      <Fragment>
        {menuContent.map((value) => (
          <div
            className={ClassNames([
              "mobile_nav_item-con",
              {
                // 是否有第二级菜单
                "border-bottom": subMenu.data[value.key],
                expandClose: subMenu.data[value.key],
                expandOpen: firstLevelKey === value.key,
              },
            ])}
            key={`nav_${value.key}`}
          >
            <div
              className="mobile_nav_item"
              onClick={() => {
                if (firstLevelKey === value.key) {
                  this.setState({
                    firstLevelKey: "",
                  });
                } else {
                  this.setState({
                    firstLevelKey: value.key,
                  });
                }
              }}
            >
              {value.name}
            </div>
            {this.renderSubNav(value.key)}
          </div>
        ))}
      </Fragment>
    );
  }

  renderSubNav(key) {
    const { subMenu } = this.props;
    const { firstLevelKey, secondLevelName } = this.state;
    const NavData = subMenu.data;
    if (NavData[key]) {
      return (
        <Fragment>
          {NavData[key].map((value: NavDataItem) => {
            if (value.content) {
              // 包含三级菜单
              return (
                <div
                  className={ClassNames([
                    "mobile_nav_item-sub-con",
                    "expandClose",
                    {
                      expandOpen: secondLevelName === value.name,
                    },
                  ])}
                  key={`nav_sub_${value.name}`}
                >
                  <div
                    className="mobile_nav_item-sub"
                    onClick={() => {
                      if (secondLevelName === value.name) {
                        this.setState({
                          secondLevelName: "",
                        });
                      } else {
                        this.setState({
                          secondLevelName: value.name,
                        });
                      }
                    }}
                  >
                    {value.name}
                  </div>
                  {this.renderThridNav(value.content)}
                </div>
              );
            } else {
              // 不包含三级菜单
              return this.renderThridNav([value]);
            }
          })}
        </Fragment>
      );
    }
    return null;
  }

  renderThridNav(navData) {
    return (
      <Fragment>
        {navData.map((value) => (
          <div key={`nav_thrid_${value.name}`} className="mobile_nav_item-third-con">
            <div className="mobile_nav_item-third">{value.name}</div>
          </div>
        ))}
      </Fragment>
    );
  }

  render() {
    return <div className="mobile_nav_container">{this.renderNavItem()}</div>;
  }
}
