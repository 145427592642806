import React from "react";
import style from "./index.module.scss";
import moment from 'moment'
export interface IAppProps {
  title: string;
  backColor: string;
  icon: {
    arrow: string;
    new: {
      text: string;
      bg: string;
    };
  };
  btn: [
    {
      text: string;
      link: string;
    },
  ];
  notification_list_article_link: string;
  notification_list: {
    data: {
      data: {
        josCmsArticle: [
          {
            articleTitle: string;
          },
        ];
      };
    };
  };
}
const App: React.SFC<IAppProps> = (props) => {
  const { title, backColor, icon, btn, notification_list, notification_list_article_link } = props;
  console.log(props);
  const newList =
    notification_list && notification_list.data && notification_list.data.data
      ? notification_list.data.data.josCmsArticle.slice(0,3)
      : [];
  return (
    <div className={`${style.appContainer}`} style={{ background: backColor }}>
      <div className={`${style.app}`}>
        <div className={style.contentWrapper}>
          <p className={style.title}>{title}</p>
          <ul>
            {newList &&
              newList.map((item, index) => (
                <li
                  key={index}
                  style={{
                    paddingLeft:
                      Math.abs(Math.floor((new Date(item.modified) - new Date()) / (1000 * 60 * 60 * 24))) < 3 &&
                      "16px",
                  }}
                >
                  {Math.abs(Math.floor((new Date(item.modified) - new Date()) / (1000 * 60 * 60 * 24))) < 3 && (
                    <em style={{ background: `${icon.new.bg}` }} className={style.icon}>
                      {icon.new.text}
                    </em>
                  )}
                  <a href={`${notification_list_article_link}${item.id}`} className={style.articleLink} target="_blank">
                    <span>{moment(item.modified).format('YYYY-MM-DD')}</span>
                    <p>{item.articleTitle}</p>


                    <i
                      className={style.arrow}
                      style={{
                        background: `url(${icon.arrow}) no-repeat 0 0 / cover`,
                      }}
                    />
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className={style.btnWrapper}>
          {btn.map((item, index) => (
            <a key={index} href={item.link} target='_blank'>
              {item.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
