import * as React from "react";
import * as ReactDOM from "react-dom";
import Commonnav from "@/layouts/commonnav/commonnav";
import { EnhanceWidget, fetchDataSource } from "@jdwork/jdwork-sdk";

import ZeusHomepageRatation from "@/widgets/zeus_homepage_ratation";
import ZeusHomepageRatationData from "./data/zeus_homepage_ratation/data";

import ZeusHomepageNotification from "@/widgets/zeus_homepage_notification";
import ZeusHomepageNotificationData from "./data/zeus_homepage_notification/data";

import ZeusMap from "@/widgets/zeus_map";
import ZeusMapData from "./data/zeus_map/data";

import ZeusHomepageTabContainer from "@/widgets/zeus_homepage_tab_container";
import ZeusHomepageTabContainerData from "./data/zeus_homepage_tab_container/data";

import ZeusHomepageSuccessCase from "@/widgets/zeus_homepage_success_case";
import ZeusHomepageSuccessCaseData from "./data/zeus_homepage_success_case/data";

import ZeusHomepageTecSupport from "@/widgets/zeus_homepage_tec_support";
import ZeusHomepageTecSupportData from "./data/zeus_homepage_tec_support/data";

import ZeusFlow from "@/widgets/zeus_flow";
import ZeusFlowData from "./data/zeus_flow/data";

class Home extends React.Component {
  dataSourceMap: Record<string, any>;
  constructor(props) {
    super(props);
    this.dataSourceMap = new Map();
  }
  componentDidMount() {
    this.dataSourceMap.size > 0 && fetchDataSource(this.dataSourceMap);
  }
  render() {
    const { dataSourceMap } = this;
    return (
      <Commonnav>
        <div className="jdw-container">
          <div className="jdw-row">
            <div className="jdw-column" style={{ backgroundColor: "#fafafa" }}>
              <div className="jdw-widget" data-name="zeus_homepage_ratation" data-version="4.0.2">
                <ZeusHomepageRatation {...ZeusHomepageRatationData} />
              </div>

              <div className="jdw-widget" data-name="zeus_homepage_notification" data-version="5.0.2">
                <EnhanceWidget
                  config={{ ...ZeusHomepageNotificationData }}
                  dataSourceMap={dataSourceMap}
                  widget={ZeusHomepageNotification}
                />
              </div>

              <div className="jdw-widget" data-name="zeus_map" data-version="3.0.0">
                <ZeusMap {...ZeusMapData} />
              </div>

              <div className="jdw-widget" data-name="zeus_homepage_tab_container" data-version="4.0.1">
                <ZeusHomepageTabContainer {...ZeusHomepageTabContainerData} />
              </div>

              <div className="jdw-widget" data-name="zeus_homepage_success_case" data-version="3.0.1">
                <ZeusHomepageSuccessCase {...ZeusHomepageSuccessCaseData} />
              </div>

              <div className="jdw-widget" data-name="zeus_homepage_tec_support" data-version="6.0.3">
                <ZeusHomepageTecSupport {...ZeusHomepageTecSupportData} />
              </div>

              <div className="jdw-widget" data-name="zeus_flow" data-version="4.0.0">
                <ZeusFlow {...ZeusFlowData} />
              </div>
            </div>
          </div>
        </div>
      </Commonnav>
    );
  }
}

ReactDOM.render(<Home />, document.getElementById("root"));
